<template>
  <div
    v-if="$root.currentUser && $root.currentUser.isAdmin()"
  >
    <div style="max-width: 1100px !important;" class="mx-auto mt-12">
      <h2>Images Uploader</h2>

      <v-card width="100%" height="400px"
              flat
              style="border: #1e3c87 dashed 4px; background-color: white"
              class="mt-10">
        <form>
          <span class="title"
                style="position: absolute; left: calc(50% - 70px); top: calc(50% - 20px)">Drop your image</span>
          <input type="file" ref="file2" class="hide_file"
                 @change="addFile"
                 accept="image/*">

        </form>
      </v-card>
      <div class="d-flex mt-5">
        <v-spacer></v-spacer>
        <v-btn
          v-if="image"
          @click="image =null, preview =null" class="px-10 mr-4" depressed>Cancel
        </v-btn>
        <v-btn class="px-10" color="primary"
               v-if="image"
               @click="uploadImage"
               depressed>Upload
        </v-btn>
      </div>

      <v-text-field v-if="textfield" class="mt-10" outlined :value="textfield"></v-text-field>
      <v-img max-height="400" contain class="mt-10" :src="preview"></v-img>
    </div>
  </div>
</template>

<script>
import {axiosServer} from "../../main";

export default {
  name: "UploadImages",
  data() {
    return {
      image: null,
      preview: null,
      textfield: null,
    }
  },
  methods: {
    addFile(event) {
      this.image = event.target.files[0]
      this.preview = URL.createObjectURL(this.image)
    },
    async uploadImage() {
      try {
        const file = this.image;

        if (file) {
          const base64 = await this.toBase64(file);

          if (base64) {
            await this.axios.post('/upload-image', {
                image_name: this.image.name,
                image_path: `other`,
                image: base64
              },
              {
                headers: {
                  Authorization: this.$root.token.value
                },
              })
            this.textfield = 'https://examenselectivitat.cat:3000/api/images/other/' + this.image.name;
            this.preview = "";
          }
        }
      } catch (e) {
        console.error(e);
      }

    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      })

    }

  }
}
</script>

<style scoped>

</style>
